define("discourse/plugins/swapd-interested/discourse/initializers/extend-for-interested", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "I18n", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _ajax, _I18n, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function registerTopicFooterButtons(api, container) {
    api.registerTopicFooterButton({
      id: "swapd-interested",
      icon: "comment-medical",
      priority: 250,
      title() {
        return this.get("topic.interested_button_enabled") ? "swapd_interested.button.title" : "swapd_interested.button.title_disabled";
      },
      label: "swapd_interested.button.label",
      action() {
        return (0, _ajax.ajax)(`/t/${this.topic.id}/interested`, {
          type: "POST"
        }).then(result => {
          this.set("topic.interested_button_enabled", false);
          const dialog = container.lookup("service:dialog");
          dialog.alert(_I18n.default.t("swapd_interested.confirmation"));
        }).catch(_ajaxError.popupAjaxError);
      },
      dropdown() {
        return false;
      },
      classNames: ["swapd-interested"],
      dependentKeys: ["topic.interested_button_present", "topic.interested_button_enabled"],
      displayed() {
        return this.get("topic.interested_button_present");
      },
      disabled() {
        return !this.get("topic.interested_button_enabled");
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-interested",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.swapd_interested_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.28", api => registerTopicFooterButtons(api, container));
      (0, _pluginApi.withPluginApi)("0.8.28", api => api.addPostSmallActionIcon("interested", "comment-medical"));
    }
  };
});